export default class NavigationBar {
  // Keep in sync with the CSS animation.
  ANIMATION_DURATION = 1000;

  INTERSECTION_OBSERVER_OPTIONS = {
    root: null,
    threshold: 0,
    rootMargin: '-60px 0px 0px 0px'
  }

  constructor() {
    this.init();
  }

  init() {
    this.whopper.addEventListener('click', () => this.handleWhopperClick());
    this.firstLevelLinks.forEach((link) => {
      link.addEventListener('mouseover', () => this.handleFirstLevelLinkMouseOver(link));
    });
    this.container.addEventListener('mouseleave', () => this.handleMouseLeave());
    document.addEventListener('click', (event) => this.handleOutsideClick(event));

    // Remove initial .animation-active after the animation is done, so that menu can be expanded again.
    if (this.container.classList.contains("animation-active")) {
      setTimeout(() => {
        this.container.classList.remove("animation-active");
        console.log("Animation done, menu can be expanded again.");
      }, this.ANIMATION_DURATION);
    }

    // Track visibility of hero section, so that color of navigation bar can be changed.
    if (this.hero) {
      const intersectionCallback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.container.classList.add('hover-hero');
            this.container.classList.remove('scrolled-beyond-hero');
          } else {
            this.container.classList.remove('hover-hero');
            this.container.classList.add('scrolled-beyond-hero');
          }
        });
      };
      const observer = new IntersectionObserver(intersectionCallback, this.INTERSECTION_OBSERVER_OPTIONS);
      observer.observe(this.hero);
    }

    let lastScrollTop = 0;
    window.addEventListener("scroll", () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop < 350) {
        this.container.removeAttribute('data-scrolled');
      } else {
        this.container.setAttribute('data-scrolled', 'true');
      }

      if (currentScrollTop < lastScrollTop) {
        // this.container.setAttribute('data-expanded', 'true');
        this.container.setAttribute('data-nudged-up', 'true');

      } else if (currentScrollTop > lastScrollTop) {
        if (this.container.getAttribute('data-nudged-up') === 'true') {
          this.container.removeAttribute('data-nudged-up');
          // this.container.setAttribute('data-expanded', 'false');
        }
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    });
  }

  get pinned() {
    return this.container.getAttribute('data-pinned') === 'true';
  }

  get expanded() {
    return this.container.getAttribute('data-expanded') === 'true';
  }

  get collapsed() {
    return !this.expanded;
  }

  expand() {
    this.container.setAttribute('data-expanded', 'true');
    this.preventBodyScroll();
  }

  collapse() {
    this.container.removeAttribute('data-expanded');
    this.allowBodyScroll();
  }

  pin() {
    this.container.setAttribute('data-pinned', 'true');
  }

  unpin() {
    this.container.removeAttribute('data-pinned');
  }

  preventBodyScroll() {
    document.body.classList.add('navigation-expanded');
  }

  allowBodyScroll() {
    document.body.classList.remove('navigation-expanded');
  }

  get container() {
    return document.querySelector('#siteHeader');
  }

  get whopper() {
    return this.container.querySelector('#whopper');
  }

  get hero() {
    return document.getElementById('hero');
  }

  get firstLevelLinks() {
    return this.container.querySelectorAll('#mainMenu > ul > li > a');
  }

  handleWhopperClick() {
    if (this.collapsed) {
      this.expand();
      this.pin();

    } else {
      this.unpin();
      this.collapse();
    }
  }

  handleFirstLevelLinkMouseOver(link) {
    if (this.collapsed) {
      this.expand();
    }
  }

  handleMouseLeave() {
    if (this.expanded && !this.pinned) {
      this.collapse();
    }
  }

  handleOutsideClick(event) {
    if (!this.container.contains(event.target) && this.expanded) {
      this.collapse();
      this.unpin();
    }
  }
}
